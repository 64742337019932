<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  {{ $t('MOM Provider - AI powered booking management software') }}
                </h1>
              </div>
              <div class="col-12 px-md-3">
                <p>
                  {{ $t('AI powered Simple, flexible and powerful booking software for your business. The easiest way to start, run and grow your online business. Create your profile, update the service you provide and share with your customers instantly within 60 secs.') }}
                </p>
              </div>
              <div class="col-12">
                <strong>
                  <a
                    class="custom-design custom-design-black"
                    href="/provider/login"
                  >
                    {{ $t('GO ONLINE') }}
                  </a>
                </strong>
              </div>
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="require('@/assets/images/landing/google-play-store.png')" class="gplay_img" fluid />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase">Welcome to MOM Provider App - Your Gateway to Expanding Your Service Business.</h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="require('@/assets/images/landing/Website/MOM Service.webp')" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div>
                <p>Instant Visibility: Join our platform and instantly showcase your services to a vast online audience. </p>
                <p>Effortless Booking: Simplify service booking with our intuitive interface. Customers can book your services in a few clicks, thanks to our user-friendly design.</p>
                <p>Local & Global Reach: Reach customers not only in your local area but also expand your business globally. Our platform connects you with potential clients from around the world.</p>
                <p>Secure Payments: Worried about payments? MOM Provider App ensures secure and hassle-free transactions, with funds directly deposited into your company account.</p>
                <p>Data-Driven Growth: Make informed decisions with our powerful analytics tools. Track performance, analyze trends, and refine your services for maximum profitability.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Create an online profile in just 60 seconds, in 3 easy steps') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img
                        fluid
                        :src="require(`@/assets/images/landing/provider/${item.img}`)"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <span
                    class="custom-design"
                  >
                    {{ item.title }}
                  </span>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="require(`@/assets/images/landing-provider/${menu.image}`)" class="home_img" />
          </div>
          <div class="ai_widget_label custom-design mx-auto">
            <span>{{ $t(menu.title) }}</span>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Why choose MOM to create your profile?') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>
              0% Commission: MOM Provider App is dedicated to supporting service providers without taking any commission from their earnings. This means you keep 100% of what you earn, helping you maximize your profits.
            </li>

            <li>
              Analytics & Reports: Gain valuable insights into your business performance with our robust analytics and reporting tools. Track key metrics, understand customer behavior, and make data-driven decisions to optimize your services and grow your revenue.
            </li>

            <li>
              Appointment Scheduling: Streamline your operations with our intuitive appointment scheduling feature. Easily manage bookings, set availability, and provide your customers with a convenient way to schedule your services.
            </li>

            <li>
              Agent Management: Efficiently assign agents to customer locations, ensuring timely service delivery. MOM Provider App allows you to oversee your workforce effectively and optimize agent allocation for improved customer satisfaction.
            </li>

            <li>
              Service at Customer Location: Meet your customers where they are. With the MOM Provider App, you can offer services directly at your customer's location, enhancing convenience and accessibility.
            </li>

            <li>
              Direct Payment to Bank: Say goodbye to payment delays. We facilitate direct payments to your bank account, ensuring you receive your earnings promptly and securely.
            </li>

            <li>
              Booking Dashboard: Stay organized with a user-friendly booking dashboard. Manage your appointments, track upcoming services, and access customer information all in one place.
            </li>

            <li>
              Promotion and Loyalty Programs: Boost your business with our promotion and loyalty program features. Create enticing offers, coupons, and loyalty rewards to attract and retain customers, driving growth and customer satisfaction.
            </li>

            <li>
              Cloud POS (Point of Sale): Simplify your transactions with our Cloud POS system. Accept payments seamlessly, generate receipts, and manage your finances efficiently, all from one platform.
            </li>

            <li>
              Automated Invoicing: Save time and reduce administrative burdens with automated invoicing. Generate invoices for your services effortlessly, ensuring accuracy and professionalism in your billing process.
            </li>

            <li>
              Global Payment Support: Expand your reach beyond borders with our global payment support. Accept payments with multiple providers like Stripe, PayPal, Phonepe, Razorpay in multiple currencies, catering to customers worldwide and unlocking new revenue opportunities.
            </li>

            <li>
              The MOM Provider App is designed to empower service providers with a comprehensive suite of tools and features. By offering 0% commission, powerful analytics, and a seamless booking experience, we aim to help you grow your business, increase your revenue, and thrive in today's competitive market.
            </li>
          </ol>
        </div>
      </div>
    </section>

    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center text-light">
            Pricing
          </h1>
          <p>
            Free forever, No credit card required
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-12 mx-auto">
          <SubscriptionList 
            :subscriptions="providerSubscriptions"
            @upgradeSubscription="upgradeSubscription"
            :upgradeBtn="`Subscribe`"
          />
        </div>
      </div>
    </section>

    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
  </div>
</template>

<script>
import { BBadge, BCard, BImg } from 'bootstrap-vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat } from '@/utilities'
export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'providerSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    servicesList: {
      get(){
        return [
          {
            title: 'Handyman',
            image: 'handyman.webp',
            url: '/user/store/list/restaurant',
          },
          {
            title: 'Photographer',
            image: 'photographer.webp',
            url: '/user/store/list/grocery',
          },
          {
            title: 'Repair Service',
            image: 'repair-service.webp',
            url: '/user/services/subcategories/list/salon',
          },
          {
            title: 'Laundry',
            image: 'laundry.webp',
            url: '/user/store/list/market',
          },
          {
            title: 'Cleaning Service',
            image: 'cleaning-service.webp',
            url: '/user/store/list/gifts',
          },
          {
            title: 'Salon & Spa',
            image: 'salon-spa.webp',
            url: '/user/services/subcategories/list/repairs',
          },
        ]
      }
    },
    landingItems: {
      get(){
        return [
          {
            img: 'step1.png',
            title: 'STEP 1',
            text: [
              `<strong class="text-light">
                ${this.$t('Register your profile')}
              </strong>
              - ${this.$t('Enter your business name, logo, address & other details and get your custom profile link ready in 60 seconds! with option to schedule booking at customer/provider/online from your profile.')}`
            ]
          },
          {
            img: 'step2.png',
            title: 'STEP 2',
            text: [
              `<strong class="text-light">
                ${this.$t('Update your services')}
              </strong>
              - ${this.$t('Update the services you provide, set prices, add if you provide service at your location, customer location or online with your available timeslot and allow your customers to book instantly.')}`
            ]
          },
          {
            img: 'step3.png',
            title: 'STEP 3',
            text: [
              `<strong class="text-light">
                ${this.$t('Go Online')}
              </strong>
              - ${this.$t('This is where it all begins. Your profile is ready for the world. Share the link with all your friends & family via Email, WhatsApp or Facebook and start taking bookings.')}`
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    isEmpty, dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-provider-registration'
      })
    }
  },
  async mounted() {
    if (!localStorage.getItem('providerSelectedCountryId')) {
      try {
        const ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        if(!isEmpty(ipInfo?.location)){
            if(!this.isSubscriptionCountryLoaded){
              await this.getCountries().then(data => {
                if (data._statusCode === 200) {
                    const countries = data.responseData
                    const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
                    if (isCountryExist) {
                      localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
                    }
                }
              })
            }else{
              const countries = this.countries
              const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
              }
            }
          }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
        this.getSubscriptionPackages()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/landing/provider-landing-main.webp');
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
